<script setup lang="ts">
import { __ } from '@@/bits/intl'
import type { OzBaseDialogBoxButtonData } from '@@/library/v4/components/OzBaseDialogBox.vue'
import OzBaseDialogBox, { OzContainedButtonColorScheme } from '@@/library/v4/components/OzBaseDialogBox.vue'
import OzBox, { OzBoxColors } from '@@/library/v4/components/OzBox.vue'
import OzInput from '@@/library/v4/components/OzInput.vue'
import isChromatic from 'chromatic/isChromatic'
import { computed, nextTick, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    title: string
    darkMode?: boolean | 'auto'
    iconSrc?: string | null
    iconAlt?: string | null
    body?: string
    customBodyClasses?: string
    xShadow?: boolean
    isCodeProtected?: boolean
    confirmButtonText?: string
    cancelButtonText?: string
    discardButtonText?: string
    buttonScheme?: OzConfirmationDialogBoxButtonScheme
    forceFullWidthButtons?: boolean
  }>(),
  {
    darkMode: 'auto',
    iconSrc: null,
    iconAlt: null,
    body: undefined,
    customBodyClasses: undefined,
    xShadow: false,
    isCodeProtected: false,
    confirmButtonText: undefined,
    cancelButtonText: undefined,
    discardButtonText: undefined,
    buttonScheme: OzConfirmationDialogBoxButtonScheme.Default,
    forceFullWidthButtons: false,
  },
)

const emit = defineEmits(['cancel', 'confirm', 'discard'])

// Fix this for Chromatic comparison
const generateCode = (): string =>
  isChromatic()
    ? '0539'
    : Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, '0')

const confirmationCodeInput = ref<InstanceType<typeof OzInput>>()
const codeConfirmationText = ref<string>(__('Enter this code to proceed:'))
const confirmationCode = ref<string>(generateCode())
const enteredCode = ref<string>('')
const isSubmittedCodeValid = ref<boolean>(true)

watch(enteredCode, () => {
  if (enteredCode.value.length > 0) {
    isSubmittedCodeValid.value = true
  }
})

const buttons = computed((): OzBaseDialogBoxButtonData[] => {
  const cancelButton = {
    text: props.cancelButtonText || __('Cancel'),
    emit: 'cancel',
    colorScheme: OzContainedButtonColorScheme.Secondary,
    testId: 'cancelButton',
  }

  const confirmButton = {
    text: props.confirmButtonText || __('Continue'),
    emit: 'confirm',
    colorScheme: OzContainedButtonColorScheme.Primary,
    disabled: props.isCodeProtected && !enteredCode.value.trim(),
    testId: 'confirmButton',
    isFocusedOnLoad: true,
  }

  const discardButton = {
    text: props.discardButtonText || __('Discard changes'),
    emit: 'discard',
    colorScheme: OzContainedButtonColorScheme.SecondaryDestructive,
    testId: 'discardButton',
  }

  // When the buttons are in a row, the prioritized action is right-most.
  // When the buttons are stacked, the prioritized action is top-most.
  let buttonList: OzBaseDialogBoxButtonData[]

  if (props.buttonScheme === OzConfirmationDialogBoxButtonScheme.Default) {
    buttonList = [cancelButton, confirmButton]
  } else if (props.buttonScheme === OzConfirmationDialogBoxButtonScheme.Danger) {
    buttonList = [cancelButton, { ...confirmButton, colorScheme: OzContainedButtonColorScheme.Destructive }]
  } else if (props.buttonScheme === OzConfirmationDialogBoxButtonScheme.PromoteCancellation) {
    buttonList = [{ ...confirmButton, colorScheme: OzContainedButtonColorScheme.SecondaryClear }, cancelButton]
  } else if (props.buttonScheme === OzConfirmationDialogBoxButtonScheme.SaveDiscardCancel) {
    buttonList = [
      { ...cancelButton, colorScheme: OzContainedButtonColorScheme.SecondaryClear },
      discardButton,
      confirmButton,
    ]
  } else if (props.buttonScheme === OzConfirmationDialogBoxButtonScheme.DiscardCancel) {
    buttonList = [{ ...cancelButton, colorScheme: OzContainedButtonColorScheme.SecondaryClear }, discardButton]
  } else {
    buttonList = [cancelButton, confirmButton]
  }

  return props.forceFullWidthButtons ? buttonList.reverse() : buttonList
})

const ariaDescribedBy = computed(() => {
  const ids: string[] = []
  if (props.body) ids.push('confirmation-dialog-body')
  if (props.isCodeProtected) ids.push('code-confirmation-text')
  return ids.join(' ')
})

const focus = (): void => {
  nextTick(() => confirmationCodeInput.value?.focusInput())
}

const cancel = (): void => {
  emit('cancel')
}

const confirm = (): void => {
  emit('confirm')
}

const discard = (): void => {
  emit('discard')
}

const submit = (): void => {
  if (!props.isCodeProtected) {
    confirm()
  } else if (enteredCode.value.trim() === confirmationCode.value) {
    confirm()
  } else {
    isSubmittedCodeValid.value = false
    focus()
    confirmationCode.value = generateCode()
  }
  enteredCode.value = ''
}

defineExpose({
  focus,
})
</script>

<script lang="ts">
export enum OzConfirmationDialogBoxButtonScheme {
  // `confirmButton` is highlighted as the primary action
  Default = 'Default',
  // `confirmButton` is highlighted in red to signal danger
  Danger = 'Danger',
  // `cancelButton` is positioned as the primary action. `confirmButton`'s color is muted
  PromoteCancellation = 'PromoteCancellation',
  // Features a discard button, along with save and cancel
  SaveDiscardCancel = 'SaveDiscardCancel',
  // Features a discard and a cancel button
  DiscardCancel = 'DiscardCancel',
}

export default {}
</script>

<template>
  <OzBox
    :color="$slots.subject ? OzBoxColors.Selection : OzBoxColors.None"
    :radius="24"
    :dark-mode="darkMode"
    :class="[
      'break-word-anywhere',
      // Fix the width to allow subject text to be truncated. Same value as OzBaseDialogBox.
      'w-82',
      // TODO: Fix bottom border radius bleed when subject is present
      xShadow && 'shadow-elevation-5',
    ]"
    data-testid="confirmationDialog"
  >
    <!-- Display the subject of the confirmation -->
    <slot name="subject"></slot>

    <OzBaseDialogBox
      :dark-mode="darkMode"
      :buttons="buttons"
      :force-full-width-buttons="forceFullWidthButtons"
      aria-labelledby="confirmation-dialog-title"
      :aria-describedby="ariaDescribedBy"
      @cancel="cancel"
      @confirm="submit"
      @discard="discard"
    >
      <div class="px-4 py-6 font-sans text-center">
        <img v-if="iconSrc" :src="iconSrc" width="64" height="64" :alt="iconAlt || ''" class="select-none pb-2.5" />
        <h2 id="confirmation-dialog-title" class="font-semibold text-heading-3 break-words px-4 pb-2.5">{{ title }}</h2>
        <p
          v-if="body"
          id="confirmation-dialog-body"
          :class="['text-body-small', !!customBodyClasses ? customBodyClasses : 'px-8']"
          v-html="body"
        />
        <div v-if="isCodeProtected">
          <div class="mb-6 text-body-small-posts">
            <span id="code-confirmation-text">{{ codeConfirmationText }}</span>
            <span data-pw="confirmationCode" data-testid="confirmationCode" class="font-semibold">{{
              confirmationCode
            }}</span>
          </div>
          <OzInput
            ref="confirmationCodeInput"
            :model-value="enteredCode"
            test-id="confirmationCodeInput"
            type="text"
            placeholder="0000"
            inputmode="numeric"
            pattern="[0-9]*"
            :valid="isSubmittedCodeValid ? 'yes' : 'no'"
            :validation-message="__('Wrong code')"
            :dark-mode="darkMode"
            :aria-label="__('Confirmation code')"
            @update:modelValue="enteredCode = $event"
            @keydown.enter.stop.prevent="submit"
          />
        </div>
      </div>
    </OzBaseDialogBox>
  </OzBox>
</template>
